(function() {

    'use strict';
    
    console.log('init app-wbzgv.slider.js');

    AppWBZGV.Slider = function(container, options) {
        this.opts = $.extend(this.defaults, options);
        this.container = container;
        this.slides = this.container.find(this.opts.slideSelector);

        if(this.slides.length > 1) {
            this.initSlider();
        }
    };
      
    AppWBZGV.Slider.prototype.defaults = {
        autoplay: false,
        effect: 'fade',
		    pagination: {
		      el: '.swiper-pagination',
		    },
				navigation: {
		      nextEl: '.swiper-button-next',
		      prevEl: '.swiper-button-prev',
		    },
        prevButton: '.swiper-button-prev',
        slideSelector: '.swiper-slide',
        spaceBetween: 0,
        speed: 400
    };

    AppWBZGV.Slider.prototype.initSlider = function() {
        new Swiper(this.container, this.opts);
    };
		
}());